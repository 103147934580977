<template>
  <div
    class="jewel"
    :style="{ backgroundPosition: `-${shift}px 0px` }"
  >
    <!-- <img
      v-for="frame in frames"
      v-show="src === frame"
      :key="frame"
      :src="require(`@/assets/jewel/${('00' + frame).slice(-3)}.png`)"
    > -->
  </div>
</template>

<script>
// import Aion from '@adoratorio/aion';
import { mapGetters } from 'vuex';
// import { lerp } from '@/assets/js/utils.js';

export default {
  data() {
    return {
      engine: null,
      src: 1,
      scrollY: 0,
      frames: 60,
      timer: null,
      loader: null,
      lastRotation: false,
    };
  },
  watch: {
    transition(value) {
      if (!value) {
        this.loading();
      } else this.lastRotation = true;
    },
  },
  computed: {
    ...mapGetters(['transition']),
    shift() {
      let size = 50;
      if (this.$mq.isMobile) {
        size = 30;
      }
      return this.src * size;
    },
  },
  // mounted() {
  //   this.engine = new Aion();
  //   this.engine.add(this.spinJewel, 'spinJewel', true);
  //   this.engine.start();
  // },
  // beforeDestroy() {
  //   this.engine.stop();
  // },
  methods: {
    // spinJewel() {
    //   const { scrollY } = window;

    //   if (scrollY === this.scrollY) {
    //     return;
    //   }

    //   clearTimeout(this.timer);

    //   if (scrollY > this.scrollY) {
    //     if (this.src === this.frames) {
    //       this.src = 1;
    //     } else this.src += 1;
    //   } else if (this.src === 1) {
    //     this.src = this.frames;
    //   } else this.src -= 1;

    //   this.scrollY = scrollY;

    //   this.timer = setTimeout(() => {
    //     this.backToStart();
    //   }, 300);
    // },
    loading() {
      clearInterval(this.loader);
      this.loader = setInterval(() => {
        if (this.src === this.frames - 1) {
          this.src = 1;
          if (this.lastRotation) {
            clearInterval(this.loader);
            this.lastRotation = false;
          }
        } else this.src += 1;
      }, 30);
    },
    // backToStart() {
    //   let direction = true;
    //   let interval = 12;

    //   if (this.src < this.frames / 2) {
    //     direction = false;
    //   }

    //   this.engine.stop();

    //   function changeFrame(vue) {
    //     setTimeout(() => {
    //       if (direction) {
    //         vue.src += 1;
    //       } else vue.src -= 1;

    //       if (vue.src >= vue.frames || vue.src <= 1) {
    //         vue.engine.start();
    //       } else {
    //         interval += 1;
    //         changeFrame(vue);
    //       }
    //     }, interval);
    //   }

    //   changeFrame(this);
    // },
  },
};
</script>

<style lang="scss">
  .jewel {
    --jewel-size: 30px;

    @include mq(m) {
      --jewel-size: 50px;
    }

    background-image: url("~@/assets/jewel/jewel.png");
    background-repeat: no-repeat;
    background-size: auto var(--jewel-size);
    display: grid;
    position: fixed;
    right: calc(var(--spacer-xs) / 2);
    top: 0;
    z-index: 3;
    height: var(--jewel-size);
    width: var(--jewel-size);

    // img {
    //   grid-area: 1 / 1;
    //   height: var(--jewel-size);
    //   margin: var(--spacer-xs);
    //   width: var(--jewel-size);
    // }
  }
</style>
