var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('main',{staticClass:"product"},[_c('header',{staticClass:"product-header"},[(_vm.productGallery.length)?_c('Gallery',{ref:"product-gallery",staticClass:"product__gallery",attrs:{"data":_vm.productGallery},on:{"change-material":_vm.changeMaterial}}):_vm._e(),(_vm.hasVariations)?_c('div',{staticClass:"variation-selector"},_vm._l((_vm.variations),function(variation){return _c('div',{key:variation.id},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.attributes.find((attribute) => attribute.id === 'pa_material')
              .model
          ),expression:"\n            attributes.find((attribute) => attribute.id === 'pa_material')\n              .model\n          "}],attrs:{"id":variation.id,"type":"radio","name":"variation"},domProps:{"value":variation.attributes_slugs.pa_material,"checked":_vm._q(
            _vm.attributes.find((attribute) => attribute.id === 'pa_material')
              .model
          ,variation.attributes_slugs.pa_material)},on:{"change":[function($event){_vm.$set(_vm.attributes.find((attribute) => attribute.id === 'pa_material')
              , "model", variation.attributes_slugs.pa_material)},_vm.updateSelectedVariation]}}),_c('label',{style:({
            background: _vm.materialColor(variation.attributes_slugs.pa_material),
          }),attrs:{"for":variation.id}})])}),0):_vm._e(),_c('div',{class:[
        'info',
        _vm.post.acf.text_color === 'white' ? 'info--white' : false,
      ]},[_c('Title',{attrs:{"value":_vm.post.name,"typo":"h3--trimmed"}}),_c('Richtext',{attrs:{"theme":"italic","typo":"h3--trimmed","value":_vm.description}}),(_vm.product)?_c('Price',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.product.price !== '' && _vm.product.price !== '0'),expression:"product.price !== '' && product.price !== '0'"}],attrs:{"typo":"h3--trimmed","theme":"inline","product":_vm.product}}):_vm._e(),_c('Enquiry',{attrs:{"theme":"plain","subject":_vm.product.name}}),_c('div',{staticClass:"info--mobile"},[_c('Link',{attrs:{"theme":"uppercase","typo":"h3","icon":_vm.mobileInfo ? 'minus' : 'plus',"data":{ title: _vm.$labels().info_cta, fn: _vm.toggleMobileInfo },"icon-first":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileInfo),expression:"mobileInfo"}],staticClass:"body"},[_c('div',[_c('Richtext',{attrs:{"theme":"italic","typo":"h3","value":_vm.description}}),(_vm.product)?_c('Price',{attrs:{"theme":"inline","product":_vm.product}}):_vm._e()],1),_c('Enquiry',{attrs:{"theme":"plain","subject":_vm.product.name}})],1)],1)],1)],1),_c('RelatedProducts',{attrs:{"category":_vm.post.gds_taxonomies.product_cat[0],"products":_vm.post.related}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }