var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader),expression:"!$route.meta.hideHeader"}],class:[
      'header',
      _vm.mobileMenu ? 'header--mobile' : false,
      _vm.$route.name === 'Home' || (_vm.currentPost ? _vm.currentPost.acf.text_color === 'white' : null)
        ? 'header--home'
        : false,
    ],on:{"click":function($event){_vm.mobileMenu ? _vm.toggleMobileMenu() : false}}},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"typo--p--trimmed"},[_c('router-link',{staticClass:"logo",attrs:{"to":_vm.lang === _vm.defaultLang ? '/' : `/${_vm.lang}/`}},[_vm._v(" "+_vm._s(_vm.$store.state.wp.site.name)+" ")])],1),_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],staticClass:"placeholder typo--p--trimmed"},[_vm._v("   ")]),(_vm.$route.name !== 'Product')?_c('LanguageSelector'):_vm._e()],1),_c('button',{staticClass:"mobile-menu-button notranslate",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMobileMenu.apply(null, arguments)}}},[_c('div',{staticClass:"typo--p--trimmed",domProps:{"innerHTML":_vm._s(_vm.mobileMenu ? _vm.$labels().close_cta : _vm.$labels().menu_cta)}})]),(_vm.$route.name === 'Product' && !_vm.$mq.isMobile)?_c('Link',{attrs:{"data":{
        title: _vm.$labels().back_cta,
        fn: _vm.goBack,
      },"theme":"plain"}}):_c('Menu',{attrs:{"theme":_vm.mobileMenu ? 'mobile' : ''}}),_c('div',{staticClass:"social"},_vm._l((_vm.social),function(item){return _c('Link',{key:item.id,attrs:{"data":{ ...item, target: '_blank' },"theme":"plain"}})}),1),_c('LanguageSelector',{attrs:{"id":"language-selector--mobile"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }