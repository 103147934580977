<template>
  <main :class="['page', $route.name === 'Home' ? 'page--home' : false]">
    <Gutenberg
      theme="page"
      :data="post.gds_blocks"
    />
    <AppFooter />
  </main>
</template>

<script>
import data from '@/mixins/data';
import AppFooter from '@/components/ui/footer';
import Gutenberg from '@/components/blocks';

export default {
  name: 'Page',
  components: {
    Gutenberg,
    AppFooter,
  },
  mixins: [data],
};
</script>

<style lang="scss">
  .page:not(.page--home) {
    display: grid;
    row-gap: var(--row-gap-gutenberg);
    padding: var(--spacer-page);
    margin-top: var(--spacer-page-top);
    margin-bottom: var(--spacer-page-bottom);
  }
</style>
