/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import useAuthorizationAndRetryMiddleware from './auth';

let instance = null;

class GDSWC {
  ajax = null;

  constructor() {
    if (instance) return instance;

    // eslint-disable-next-line no-undef
    const url = __VUE_WORDPRESS__.routing.url.replace(':8888', ':3000');
    this.ajax = axios.create(
      {
        baseURL: `${url}/wp-json/`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    useAuthorizationAndRetryMiddleware(this.ajax);
    instance = this;
    return instance;
  }

  fetchVariations = ({ params }) => this.ajax.get(`gds/v1/wc/products/${params.slug}/variations/`, { params });

  getCountries = () => this.ajax.get('gds/v1/wc/countries/', { params: null });

  createOrder = async (body) => {
    try {
      return await this.ajax.post('gds/v1/wc/orders/', body, { params: null });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  getOrder = async (params) => this.ajax.get(`gds/v1/wc/orders/${params.order_id}/`, { params });

  cancelOrder = async (params) => this.ajax.delete(`gds/v1/wc/orders/${params.order_id}/`, { params });

  getOrders = async (params) => this.ajax.get('gds/v1/wc/orders/', { params });

  createUser = async (body) => this.ajax.post('gds/v1/wc/account/', body, { params: null });

  getUser = async (body) => this.ajax.get('gds/v1/wc/account/', body, { params: null });

  getCustomer = async (body) => {
    try {
      return await this.ajax.get('gds/v1/wc/customer/', body, { params: null });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  updateAddress = async (body) => this.ajax.put('gds/v1/wc/customer/', body);

  updateUser = async (body) => this.ajax.put('gds/v1/wc/account/', body);

  addCoupon = async (body) => {
    try {
      return await this.ajax.post('gds/v1/wc/checkout/add-coupon/', body);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  removeCoupon = async (body) => {
    try {
      return await this.ajax.post('gds/v1/wc/checkout/remove-coupon/', body);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  setShippingMethod = async (body) => {
    try {
      return await this.ajax.post('gds/v1/wc/checkout/shipping-method/', body);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  createPaymentIntent = async (body) => this.ajax.post('gds/v1/wc/checkout/stripe-pi/', body, { params: null });

  updatePaymentIntent = async (clientSecret) => this.ajax.put(`gds/v1/wc/checkout/payment-intent/${clientSecret}/`);

  updatePaymentMethod = async (body, data) => {
    try {
      return await this.ajax.put(`gds/v1/wc/orders/${data.orderId}/pay`, body, { params: { key: data.orderKey } });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  createSetupIntent = async ({
    source,
    newAccountCredentials,
  }) => {
    try {
      return await this.ajax.post('gds/v1/wc/customer/stripe-si/', {
        source,
        newAccountCredentials,
      }, { params: null });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  assignSourceToCustomer = async ({
    source,
    newAccountCredentials,
  }) => {
    try {
      return await this.ajax.post('gds/v1/wc/customer/stripe-source/', {
        source,
        newAccountCredentials,
      }, { params: null });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  createPaymentSource = async ({
    source,
    newAccountCredentials,
  }) => {
    try {
      return await this.ajax.post('gds/v1/wc/customer/payment-source/', {
        source,
        newAccountCredentials,
      }, { params: null });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  setDefaultPaymentSource = async ({ payment_source_id }) => {
    try {
      return await this.ajax.patch(`gds/v1/wc/customer/payment-source/${payment_source_id}/default/`);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  deletePaymentSource = async ({ payment_source_id }) => {
    try {
      return await this.ajax.delete(`gds/v1/wc/customer/payment-source/${payment_source_id}/`);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  getSubscription = async (params) => this.ajax.get(`gds/v1/wc/subscriptions/${params.subscription_id}/`, { params });

  getSubscriptions = async (params) => this.ajax.get('gds/v1/wc/subscriptions/', { params });

  changeSubscriptionStatus = async (params) => this.ajax.put(`gds/v1/wc/subscriptions/${params.subscription_id}/status/${params.status}`);

  updateSubscriptionPaymentMethod = async (body, data) => {
    try {
      return await this.ajax.put(`gds/v1/wc/subscriptions/${data.subscriptionsId}/payment-source/`, body, { params: { key: data.orderKey, change_payment_method: data.subscriptionsId } });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  updateSubscriptionShipping = async (params, body) => this.ajax.put(`gds/v1/wc/subscriptions/${params.subscription_id}/shipping/`, body);

  getCart = async () => {
    const data = await this.ajax.get('cocart/v2/cart/');
    if ((Array.isArray(data.data) && data.data.length === 0) || data.data.item_count === 0) {
      return data;
    }

    const { data: cartTotals } = await this.ajax.get('cocart/v2/cart/totals/', { params: { html: true } });
    data.data.totals = cartTotals;

    return data;
  };

  calculateCart = async () => {
    try {
      const calc = await this.ajax.post('cocart/v2/cart/calculate/');
      return calc;
    } catch (e) {
      if (e.response.status === 400 && e.response.data.code === 'cocart_response_returned_empty') {
        return null;
      }
      throw e;
    }
  };

  addToCart = ({ payLoad }) => this.ajax.post('cocart/v2/cart/add-item/', payLoad);

  updateCartItem = async ({ payLoad }) => {
    try {
      return await this.ajax.post(`cocart/v2/cart/item/${payLoad.item_key}`, payLoad);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  removeCartItem = async ({ payLoad }) => {
    try {
      return await this.ajax.delete(`cocart/v2/cart/item/${payLoad.item_key}`, { params: { return_status: true } });
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  clearCart = async () => {
    const { status } = await this.ajax.post('cocart/v2/cart/clear/');
    return status === 200;
  };

  getCartPaymentGateways = () => this.ajax.get('cocart/v1/payment-methods/', { params: null });

  setPaymentGateway = (body) => this.ajax.post('cocart/v1/payment-methods/', body);

  calculateShippingMethods = async (params) => {
    try {
      return await this.ajax.post('cocart/v1/calculate/shipping/', params);
    } catch (error) {
      const { response } = error;
      return response;
    }
  };

  getCoupons = async () => this.ajax.get('cocart/v1/coupon/');

  // preview
  fetchSingleById = ({ type, params = {} }) => {
    if (type === 'product') {
      return this.ajax.get(`gds/v1/wc/productsById/${params.id}`);
    }
    return this.ajax.get(`wp/v2/${type}/${params.id}`);
  };
}

export default GDSWC;
