<template>
  <compoment
    :is="tag"
    :class="[
      'link',
      themeClass,
      disabled ? 'link--disabled' : false,
      iconFirst ? 'link--icon-first' : false,
    ]"
    :href="external ? url : undefined"
    :to="!external ? url : undefined"
    :target="link.target"
    @[event]="data.fn"
    @click.stop
  >
    <div :class="`typo--${typo}--trimmed`">
      {{ title }}
    </div>
    <Icon
      v-if="icon"
      :theme="theme"
      :name="icon"
    />
  </compoment>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Link',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    iconFirst: {
      type: Boolean,
      default: false,
    },
    typo: {
      type: String,
      default: 'p',
    },
  },
  computed: {
    event() {
      return this.data.fn ? 'click' : null;
    },
    tag() {
      return this.data.fn ? 'button' : this.external ? 'a' : 'router-link';
    },
    link() {
      return this.data.value ? this.data.value.link : this.data;
    },
    title() {
      return this.link.title || this.link.content;
    },
    url() {
      if (this.link.url && typeof this.link.url === 'string') {
        return this.$relativeUrl(this.link.url);
      }
      return this.link.url;
    },
    external() {
      return (
        this.url
        && typeof this.url === 'string'
        && (this.url.startsWith('http') || this.url.startsWith('mailto'))
      );
    },
    themeClass() {
      return this.theme
        ? `link--${this.theme}`
        : this.settings.theme
          ? `link--${this.settings.theme}`
          : false;
    },
  },
};
</script>

<style lang="scss">
a {
  &.router-link-active {
    // style
  }
}

.link--disabled {
  opacity: 0.5;
  pointer-events: none;

  * {
    pointer-events: none !important;
  }
}

.link {
  align-items: center;
  // display: grid;
  gap: var(--column-gap-xs);
  grid-auto-flow: column;
  width: max-content;

  &--plain {
    text-decoration: none;
  }

  &--button {
    border-radius: var(--border-radius);
    background: var(--black);
    color: var(--white);
    padding: var(--spacer-xs) var(--spacer-m);
    text-align: center;
  }

  &--uppercase {
    text-decoration: none;
    text-transform: uppercase;
  }

  &--enquiry {
    text-decoration: none;
    text-transform: uppercase;

    color: var(--rose);

    svg {
      stroke: var(--rose);
    }
    @media (hover: hover) {
      &:hover {
        color: var(--black);
        svg {
          stroke: var(--black);
        }
      }
    }

    &--plain {
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  &--grey {
    text-decoration: none;
    display: inline;

    color: var(--rose);
    @media (hover: hover) {
      &:hover {
        color: var(--black);
      }
    }
  }

  &--icon-first {
    .icon {
      grid-column: 1;
    }
  }
}
</style>
