<template>
  <div class="product-preview product-preview--two-right">
    <div class="media placeholder">
      <Media
        v-if="value.media_one.length > 0"
        :data="value.media_one[0]"
      />
      <Thumb
        :data="value.product"
        placeholder
      />
    </div>
    <Thumb
      theme="collection"
      :data="value.product"
    />
  </div>
</template>

<script>
import Media from '@/components/media';
import Thumb from '@/components/thumb/product';

export default {
  components: {
    Media,
    Thumb,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  .product-preview {
    &--two-right {
      gap: var(--column-gap-xs);
      grid-template-columns: repeat(var(--preview-columns), minmax(0, 1fr));

      @include mq(m) {
        --preview-columns: 3;
      }

      > :first-child {
        grid-column-end: -2;
      }

      > .thumb {
        grid-column-end: -1;
      }
    }
  }
</style>
