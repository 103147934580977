var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['columns', `columns--${_vm.data.settings.spacing}`]},[(_vm.data.value.title && _vm.data.value.title !== '')?_c('Title',{attrs:{"value":_vm.data.value.title,"theme":"uppercase","typo":"h3--trimmed"}}):_vm._e(),_c('div',{staticClass:"body"},_vm._l((_vm.data.value.columns),function(column,index){return _c('div',{key:index,style:({
        gridColumn: _vm.$mq.isMobile
          ? 'initial'
          : `${_vm.columnStart(index)} / span 2`,
      })},[_c('header',{staticClass:"column-header"},[(column.icon)?_c('Figure',{attrs:{"theme":"icon","disable-ratio":"","lazyload":false,"data":{
            value: { src: column.icon },
            settings: {},
          }}}):_vm._e(),(column.title && column.title !== '')?_c('Title',{attrs:{"value":column.title,"theme":"uppercase","typo":"h3--trimmed"}}):_vm._e()],1),(column.text && column.text !== '')?_c('RichText',{attrs:{"value":column.text}}):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }