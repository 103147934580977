var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{directives:[{name:"visible",rawName:"v-visible",value:(!_vm.placeholder),expression:"!placeholder"}],class:[
    'thumb',
    'thumb--product',
    _vm.theme ? `thumb--product--${_vm.theme}` : false,
    _vm.placeholder ? 'placeholder' : false,
  ],attrs:{"to":_vm.$relativeUrl(_vm.data.permalink)}},[(_vm.data.gds_featured_image && !_vm.placeholder)?_c('Figure',{attrs:{"lazyload":false,"data":{
      value: {
        src: _vm.data.gds_featured_image,
      },
      settings: {},
    },"theme":"thumb"}}):_vm._e(),_c('div',{staticClass:"typo--h3--trimmed"},[(_vm.data.name)?_c('h2',{staticClass:"thumb-title"},[_vm._v(" "+_vm._s(_vm.data.name)+" ")]):_vm._e(),_c('p',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}}),(_vm.theme !== 'related' && _vm.data.price !== '' && _vm.data.price !== '0')?_c('Price',{attrs:{"product":_vm.data}}):_vm._e(),(_vm.theme !== 'related')?_c('Enquiry',{attrs:{"subject":_vm.data.name}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }