<template>
  <div class="archive">
    <Grid
      v-if="items.length > 0"
      column-gap="xs"
      :col="{ default: 6, m: 4 }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumbs.get('product')"
          :data="item"
        />
      </div>
    </Grid>

    <div
      v-show="items && data.settings.infinite_scroll"
      ref="infinite"
      class="infinite"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import medusa from '@/assets/js/observer';

import Thumb from '@/components/thumb';
import ThumbProduct from '@/components/thumb/product';
import Title from '@/components/typo/title';
import Link from '@/components/typo/link';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('product', ThumbProduct);

export default {
  name: 'Archive',
  components: {
    Title,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      items: [],
      offset: 0,
      placeholder: 'Loading',
    };
  },
  computed: {
    ...mapGetters(['postType']),
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      if (this.data.settings.infinite_scroll) {
        this.$nextTick(this.setInfiniteScroll);
      } else {
        this.$root.$children[0].lazyObserve(this.$el);
      }
    });
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('infinite-scroll')) {
      medusa.ref.removeTarget('infinite-scroll');
    }
  },
  methods: {
    async loadItems() {
      const items = await this.$store.dispatch('getItems', this.request);
      if (items) {
        this.items = [...this.items, ...items];
      }

      return items;
    },
    setRequest() {
      const type = this.postType('product').rest_base;
      const per_page = parseInt(this.data.settings.posts_per_page, 10);
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by;
      const { offset } = this;

      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(':');
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = parseInt(string[1], 10);
      });

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: 'infinite-scroll',
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget('infinite-scroll', this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      const { height, top } = this.$el.getBoundingClientRect();
      this.offset += parseInt(this.data.settings.posts_per_page, 10);
      this.setRequest();
      this.loadItems().then((newItems) => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget('infinite-scroll');
        } else if ((window.pageYOffset + window.innerHeight) >= height + top) {
          this.requestOffset();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;

  > .grid {
    row-gap: var(--row-gap-archive);
  }
}
.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
}
</style>
