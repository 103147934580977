<template>
  <router-link
    v-visible="!placeholder"
    :to="$relativeUrl(data.permalink)"
    :class="[
      'thumb',
      'thumb--product',
      theme ? `thumb--product--${theme}` : false,
      placeholder ? 'placeholder' : false,
    ]"
  >
    <Figure
      v-if="data.gds_featured_image && !placeholder"
      :lazyload="false"
      :data="{
        value: {
          src: data.gds_featured_image,
        },
        settings: {},
      }"
      theme="thumb"
    />
    <div class="typo--h3--trimmed">
      <h2
        v-if="data.name"
        class="thumb-title"
      >
        {{ data.name }}
      </h2>
      <p
        class="description"
        v-html="description"
      />
      <Price
        v-if="theme !== 'related' && data.price !== '' && data.price !== '0'"
        :product="data"
      />
      <Enquiry
        v-if="theme !== 'related'"
        :subject="data.name"
      />
    </div>
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Price from '@/components/wc/price.vue';
import Enquiry from '@/components/ui/enquiry';

export default {
  name: 'ProductThumb',
  components: {
    Figure,
    Price,
    Enquiry,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    description() {
      let description = '';

      if (this.data.acf && this.data.acf.description !== '') {
        description = this.data.acf.description;
      }

      if (this.data.description && this.data.description !== '') {
        description = this.data.description;
      }

      return description;
    },
  },
};
</script>

<style lang="scss" scoped>
  .thumb {
    &--product {
      align-content: start;
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      gap: var(--row-gap-xs);
      text-decoration: none;

      @include mq(m) {
        grid-auto-flow: column;
      }

      @media (hover: hover) {
        &:hover:not(.placeholder) {
          .enquiry {
            visibility: initial;
          }
        }
      }

      .thumb-title::after {
        content: " ";
        display: block;
        white-space: pre;
      }

      .description {
        font-style: italic;
        // white-space: pre-line;
      }

      .enquiry {
        @media (hover: hover) {
          visibility: hidden;
        }
        margin-top: var(--row-gap-m);
      }

      &--collection,
      &--related {
        grid-auto-flow: initial;
      }
    }
  }
</style>
