const labels = {
  de: {
    back_cta: 'Zurück',
    back_home_cta: 'Zurück zur Startseite',
    close_cta: 'Schließen',
    credits: 'website von Giga Design Studio',
    enquiry_block_body: 'Guten Tag%0D%0AIch interessiere mich für ein Schmuckstück.%0D%0AFreundliche Grüsse',
    enquiry_body: 'Guten Tag%0D%0AIch interessiere mich für dieses Schmuckstück.%0D%0AFreundliche Grüsse',
    enquiry_cta: 'Anfragen',
    info_cta: 'Info',
    menu_cta: 'Menu',
    not_found_text: '404 Seite nicht gefunden',
    price_vat_excluded: '(ohne MwSt)',
    price_vat_included: '(inkl. MwSt)',
    read_less_cta: 'Lese weniger',
    read_more_cta: 'Weiterlesen',
    related_title: 'Aus der Sammlung',
  },
  en: {
    back_cta: 'Back',
    back_home_cta: 'Back to homepage',
    close_cta: 'Close',
    credits: 'website by Giga Design Studio',
    enquiry_block_body: 'Hello%0D%0AI am interested in a jewellery.%0D%0AKind regards',
    enquiry_body: 'Hello%0D%0AI am interested in this jewellery.%0D%0AKind regards',
    enquiry_cta: 'Please enquire',
    info_cta: 'Info',
    menu_cta: 'Menu',
    not_found_text: '404 Page not found',
    price_vat_excluded: '(VAT excluded)',
    price_vat_included: '(VAT included)',
    read_less_cta: 'Read less',
    read_more_cta: 'Read more',
    related_title: 'From the collection',
  },
};

labels.install = (vm, store) => {
  vm.prototype.$labels = () => labels[store.state.lang];
};

export default labels;
