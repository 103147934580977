/* eslint-disable no-undef */

// import Cart from '@/views/wc/Cart.vue';
// import Checkout from '@/views/wc/Checkout.vue';
// import ThankYou from '@/views/wc/ThankYou.vue';
// import Account from '@/views/wc/account/Index.vue';
// import Orders from '@/views/wc/account/Orders.vue';
// import Subscriptions from '@/views/wc/account/Subscriptions.vue';
// import Register from '@/views/wc/account/Register.vue';
// import SocialLoggedIn from '@/views/wc/account/SocialLoggedIn.vue';
// import EditAccount from '@/views/wc/account/EditAccount.vue';
// import EditAddress from '@/views/wc/account/EditAddress.vue';
import Product from '@/views/wc/Product.vue';
// import payOrderSelector from './payOrderSelector';

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const permalink = postTypes.product.rewrite.slug.replace(/^\/+/g, '');
const type = postTypes.product.rest_base;

let langs = null;

if (i18n) {
  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join('|');
}

const wcRoutes = [
  // {
  //   path: '/cart',
  //   name: 'Cart',
  //   component: Cart,
  //   meta: {
  //     customView: true,
  //   },
  // },
  // /cart/?cancel_order=true&order=wc_order_wIm66dL0hWe5a&order_id=165&redirect&_wpnonce=9d9f754376
  // {
  //   path: '/checkout/order-pay/:orderId/',
  //   name: 'PayOrder',
  //   component: payOrderSelector,
  //   meta: {
  //     customView: true,
  //     fetchCountry: true,
  //   },
  // },
  // Pagina di pagamento ordine: /checkout/order-pay/260/?pay_for_order=true&key=wc_order_CeFdYg7esp6RQ
  // Pagina di pagamento ordine: /checkout/order-pay/462/?pay_for_order=true&key=wc_order_Lu2e0h5jQZaEa&change_payment_method=462
  // {
  //   path: '/checkout',
  //   name: 'Checkout',
  //   component: Checkout,
  //   meta: {
  //     customView: true,
  //     fetchCountry: true,
  //     hideHeader: true,
  //     hideFooter: true,
  //   },
  // },
  // {
  //   path: '/checkout/order-received/:orderId/',
  //   name: 'ThankYou',
  //   component: ThankYou,
  //   meta: {
  //     customView: true,
  //     fetchCountry: true,
  //   },
  // },
  // {
  //   path: '/register/',
  //   name: 'Register',
  //   component: Register,
  //   meta: {
  //     customView: true,
  //   },
  // },
  // {
  //   path: '/social-logged-in/',
  //   name: 'SocialLoggedIn',
  //   component: SocialLoggedIn,
  //   meta: {
  //     customView: true,
  //   },
  // },
  // {
  //   path: '/my-account/',
  //   name: 'Account',
  //   component: Account,
  //   meta: {
  //     customView: true,
  //     fetchCountry: true,
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'EditAccount',
  //       component: EditAccount,
  //       meta: {
  //         customView: true,
  //         userPage: true,
  //         fetchCountry: true,
  //       },
  //     },
  //     {
  //       path: 'orders',
  //       name: 'Orders',
  //       component: Orders,
  //       meta: {
  //         customView: true,
  //         userPage: true,
  //         fetchCountry: true,
  //       },
  //     },
  //     {
  //       path: 'view-order/:orderId/',
  //       redirect: (to) => ({
  //         name: 'Orders',
  //         hash: `#order-${to.params.orderId}`,
  //       }),
  //     },
  //     {
  //       path: 'edit-address/:addressType/',
  //       name: 'EditAddress',
  //       component: EditAddress,
  //       meta: {
  //         customView: true,
  //         userPage: true,
  //         fetchCountry: true,
  //       },
  //     },
  //     {
  //       path: 'subscriptions',
  //       name: 'Subscriptions',
  //       component: Subscriptions,
  //       meta: {
  //         customView: true,
  //         userPage: true,
  //         fetchCountry: true,
  //       },
  //     },
  //     {
  //       path: 'view-subscription/:subscriptionId/',
  //       redirect: (to) => ({
  //         name: 'Subscriptions',
  //         hash: `#subscription-${to.params.subscriptionId}`,
  //       }),
  //     },
  //   ],
  // },
  {
    path: langs ? `/:lang(${langs})?/${permalink}/:slug` : `/${permalink}/:slug`,
    name: 'Product',
    component: Product,
    meta: {
      type,
    },
  },
];

export default wcRoutes;
