<template>
  <nav :class="['menu', theme ? `menu--${theme}` : false]">
    <Link
      v-for="item in navigation"
      :key="item.id"
      theme="plain"
      :data="{
        url: item.url,
        title: item.content,
        target: item.target,
      }"
    />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';

export default {
  name: 'Menu',
  components: {
    Link,
  },
  props: {
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('header');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu {
    display: grid;
    gap: var(--row-gap-xs);
    justify-items: start;

    .router-link-exact-active {
      font-style: italic;
    }

    &--mobile {
      margin-top: var(--spacer-l);
      gap: var(--row-gap-s);
    }
  }
</style>
