/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import axios from 'axios';

const sessionHandler = {
  // fields
  logged: false,
  refreshTimeout: null,
  user: null,
  expiresAt: null,
  accessToken: null,
  refreshToken: null,
  axiosInstance: null,

  // overridable fields & callbacks
  log: process.env.NODE_ENV === 'development',
  passwordloginEndpoint: 'api-bearer-auth/v1/login/',
  socialLoginEndpoint: 'gds/v1/wc/auth/social-login/',
  logoutEndpoint: 'gds/v1/wc/auth/logout/',
  refreshEndpoint: 'api-bearer-auth/v1/tokens/refresh/',
  lsKeyUser: 'gds-abash-user',
  lsKeyTokens: 'gds-abash-token',
  onInitCompleted: (existsTokens) => null,
  onLoginCompleted: (data) => null,
  onLoginFailed: (error) => (sessionHandler.log ? console.warn('sessionHandler onLoginFailed', error) : null),
  onRefreshCompleted: (data) => null,
  onRefreshFailed: (error) => (sessionHandler.log ? console.warn('sessionHandler onRefreshFailed', error) : null),
  onLogoutCompleted: () => null,
  onDisposed: () => null,

  // 'private' methods
  _initOptions: (options) => {
    if (options.log != null && options.log === false) sessionHandler.log = false;
    if (options.lsKeyUser != null && options.lsKeyUser.length > 0) sessionHandler.lsKeyUser = options.lsKeyUser;
    if (options.lsKeyTokens != null && options.lsKeyTokens.length > 0) sessionHandler.lsKeyTokens = options.lsKeyTokens;
    if (options.passwordloginEndpoint != null && options.passwordloginEndpoint.length > 0) sessionHandler.passwordloginEndpoint = options.passwordloginEndpoint;
    if (options.socialLoginEndpoint != null && options.socialLoginEndpoint.length > 0) sessionHandler.socialLoginEndpoint = options.socialLoginEndpoint;
    if (options.logoutEndpoint != null && options.logoutEndpoint.length > 0) sessionHandler.logoutEndpoint = options.logoutEndpoint;
    if (options.refreshEndpoint != null && options.refreshEndpoint.length > 0) sessionHandler.refreshEndpoint = options.refreshEndpoint;
    if (options.onInitCompleted != null) sessionHandler.onInitCompleted = options.onInitCompleted;
    if (options.onLoginCompleted != null) sessionHandler.onLoginCompleted = options.onLoginCompleted;
    if (options.onLoginFailed != null) sessionHandler.onLoginFailed = options.onLoginFailed;
    if (options.onRefreshCompleted != null) sessionHandler.onRefreshCompleted = options.onRefreshCompleted;
    if (options.onRefreshFailed != null) sessionHandler.onRefreshFailed = options.onRefreshFailed;
    if (options.onLogoutCompleted != null) sessionHandler.onLogoutCompleted = options.onLogoutCompleted;
    if (options.onDisposed != null) sessionHandler.onDisposed = options.onDisposed;
  },
  _removeSessionOps: () => {
    localStorage.removeItem(sessionHandler.lsKeyUser);
    localStorage.removeItem(sessionHandler.lsKeyTokens);

    let sessionCookie = document.cookie.match(new RegExp('(^| )wordpress_logged_in_\\w+=([^;]+)'));
    if (sessionCookie && sessionCookie.length > 0) {
      sessionCookie = sessionCookie[0].trim();
      const cookieKey = sessionCookie.split('=')[0];
      console.log('cookieKey', cookieKey);
      document.cookie = `${cookieKey}=; Path=${process.env.VUE_APP_BASE_URL}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
    sessionCookie = document.cookie.match(new RegExp('(^| )wordpress_sec_\\w+=([^;]+)'));
    if (sessionCookie && sessionCookie.length > 0) {
      sessionCookie = sessionCookie[0].trim();
      const cookieKey = sessionCookie.split('=')[0];
      console.log('cookieKey', cookieKey);
      document.cookie = `${cookieKey}=; Path=${process.env.VUE_APP_BASE_URL}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    sessionHandler.user = null;
    sessionHandler.accessToken = null;
    sessionHandler.refreshToken = null;
    sessionHandler.expiresAt = null;
    sessionHandler.logged = false;
  },
  _initSessionOps: () => {
    if (localStorage.getItem(sessionHandler.lsKeyTokens) == null) {
      throw new Error('Trying to init from something that doesn\'t exists');
    }

    sessionHandler.user = JSON.parse(localStorage.getItem(sessionHandler.lsKeyUser));
    const currentTokens = JSON.parse(localStorage.getItem(sessionHandler.lsKeyTokens));
    sessionHandler.accessToken = currentTokens.at;
    sessionHandler.refreshToken = currentTokens.rt;
    sessionHandler.expiresAt = currentTokens.ea;
    sessionHandler.logged = true;
  },
  _loginSessionOps: (data) => {
    localStorage.setItem(sessionHandler.lsKeyUser, JSON.stringify(data.wp_user.data));
    const tokenJsonObject = {
      at: data.access_token,
      rt: data.refresh_token,
      ea: (Date.now() + data.expires_in * 1000).toString(),
    };
    localStorage.setItem(sessionHandler.lsKeyTokens, JSON.stringify(tokenJsonObject));

    sessionHandler.user = data.wp_user.data;
    sessionHandler.accessToken = data.access_token;
    sessionHandler.refreshToken = data.refresh_token;
    const expiresAt = Date.now() + data.expires_in * 1000;
    sessionHandler.expiresAt = expiresAt;
    sessionHandler.logged = true;
  },
  _refreshOps: (data) => {
    if (localStorage.getItem(sessionHandler.lsKeyTokens) == null) {
      throw new Error('Trying to refresh something that doesn\'t exists');
    }

    const expiresAt = Date.now() + data.expires_in * 1000;
    sessionHandler.accessToken = data.access_token;
    sessionHandler.expiresAt = expiresAt;

    const currentTokens = JSON.parse(localStorage.getItem(sessionHandler.lsKeyTokens));
    currentTokens.at = data.access_token;
    currentTokens.ea = expiresAt;
    localStorage.setItem(sessionHandler.lsKeyTokens, JSON.stringify(currentTokens));
  },
  _refresh: async () => sessionHandler.axiosInstance.post(sessionHandler.refreshEndpoint, {
    token: sessionHandler.refreshToken,
  })
    .then((axiosResponse) => {
      sessionHandler._refreshOps(axiosResponse.data);

      if (sessionHandler.refreshTimeout) clearTimeout(sessionHandler.refreshTimeout);
      const timeout = (axiosResponse.data.expires_in - 1) * 1000;
      sessionHandler.refreshTimeout = setTimeout(() => {
        sessionHandler._refresh();
      }, timeout);
      sessionHandler.onRefreshCompleted(axiosResponse.data);
    })
    .catch((error) => {
      sessionHandler.onRefreshFailed(error);
      sessionHandler.logout();
    }),

  // 'public' methods
  init: (options) => {
    sessionHandler._initOptions(options);

    const url = __VUE_WORDPRESS__.routing.url.replace(':8888', ':3000');
    sessionHandler.axiosInstance = axios.create({ baseURL: `${url}/wp-json/` });

    const existsTokens = localStorage.getItem(sessionHandler.lsKeyTokens) != null;
    if (existsTokens) {
      sessionHandler._initSessionOps();
      const timeout = Math.max(sessionHandler.expiresAt - Date.now(), 1);
      sessionHandler.refreshTimeout = setTimeout(() => {
        sessionHandler._refresh();
      }, timeout);
    }

    sessionHandler.onInitCompleted(existsTokens);
  },
  login: async (payload) => {
    try {
      let axiosResponse = null;

      if (payload != null) {
        const body = {
          username: payload.username,
          password: payload.password,
        };
        axiosResponse = await sessionHandler.axiosInstance.post(sessionHandler.passwordloginEndpoint, body);
      } else {
        axiosResponse = await sessionHandler.axiosInstance.get(sessionHandler.socialLoginEndpoint, {
          headers: {
            'X-WP-Nonce': __VUE_WORDPRESS__.state.session.nonce,
          },
        });
      }

      sessionHandler._loginSessionOps(axiosResponse.data);

      if (sessionHandler.refreshTimeout) clearTimeout(sessionHandler.refreshTimeout);
      const timeout = ((axiosResponse.data.expires_in - 1) * 1000);
      sessionHandler.refreshTimeout = setTimeout(() => {
        sessionHandler._refresh();
      }, timeout);
      sessionHandler.onLoginCompleted(axiosResponse.data);
      return true;
    } catch (axiosResponse) {
      sessionHandler.onLoginFailed(error);
      return error;
    }
  },
  logout: async () => {
    const headers = { };
    if (__VUE_WORDPRESS__.state.session && __VUE_WORDPRESS__.state.session.nonce) {
      headers['X-WP-Nonce'] = __VUE_WORDPRESS__.state.session.nonce;
    }
    try {
      await sessionHandler.axiosInstance.get(sessionHandler.logoutEndpoint, {
        headers,
      });
    } catch (e) {
      // no-op
    }
    sessionHandler._removeSessionOps();
    if (sessionHandler.refreshTimeout) clearTimeout(sessionHandler.refreshTimeout);
    sessionHandler.onLogoutCompleted();
  },
  dispose: () => {
    if (sessionHandler.refreshTimeout) clearTimeout(sessionHandler.refreshTimeout);
    sessionHandler.onDisposed();
  },
};

export default sessionHandler;
