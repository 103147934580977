<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Figure
      v-if="data.gds_featured_image"
      :data="{
        value: {
          image: data.gds_featured_image,
        },
        settings: {}
      }"
    />
    <Title :value="data.title.rendered" />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
