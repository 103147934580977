<template>
  <div class="widgets no-scrollbar">
    <Cookies v-if="options && options.cookies" />
    <News
      v-for="item in news"
      :key="item.id"
      :value="item"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Cookies from '@/components/ui/cookie';
import News from '@/components/ui/news';

export default {
  name: 'Widgets',
  components: {
    Cookies,
    News,
  },
  data() {
    return {
      news: [],
    };
  },
  computed: {
    ...mapGetters(['options', 'lang']),
  },
  mounted() {
    this.loadNews();
  },
  methods: {
    async loadNews() {
      const request = {
        type: 'news',
        params: {
          lang: this.lang,
          per_page: 4,
        },
      };

      this.news = await this.$store.dispatch('getItems', request);
    },
  },
};
</script>

<style lang="scss" scoped>
  .widgets {
    bottom: 0;
    display: grid;
    gap: var(--row-gap-xs);
    left: 0;
    margin: 0 auto;
    -webkit-mask-image: linear-gradient(
      transparent 0%,
      black var(--spacer-m),
      black calc(100% - var(--spacer-m)),
      transparent 100%
    );
    mask-image: linear-gradient(
      transparent 0%,
      black var(--spacer-m),
      black calc(100% - var(--spacer-m)),
      transparent 100%
    );
    max-height: calc(var(--vh) * 100);
    overflow-y: auto;
    position: fixed;
    right: 0;
    z-index: 1;
    width: calc(100% - 2 * var(--spacer-m));
    max-width: 240px;

    @include mq(s) {
      left: initial;
      margin: 0 var(--spacer-m);
    }

    > * {
      background: var(--semi-transparent);
      border-radius: var(--border-radius);
      // box-shadow: 0px 0px 4px 2px rgb(240,240,240);
      display: grid;
      gap: var(--column-gap-xs);
      grid-auto-flow: column;
      justify-content: space-between;
      padding: var(--spacer-s);
    }

    > :first-child {
      margin-top: var(--spacer-m);
    }
    > :last-child {
      margin-bottom: var(--spacer-m);
    }
  }
</style>
