import * as dayjs from 'dayjs';
import currencyFormatter from 'currency-formatter';
import GDSWC from '@/api/wc';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

export const unparseCurrency = (price, wcSettings) => {
  const unformatOptions = {
    code: wcSettings.woocommerce_currency,
    decimal: wcSettings.woocommerce_price_decimal_separator,
    thousand: wcSettings.woocommerce_price_thousand_separator,
  };
  return currencyFormatter.unformat(price, unformatOptions);
};

export const parseCurrency = (price, wcSettings, forceValue = false) => {
  let priceNumber = 0;
  if (price) {
    priceNumber = price;
  }
  const formatOptions = {
    code: forceValue || wcSettings.woocommerce_currency,
    decimal: wcSettings.woocommerce_price_decimal_separator,
    thousand: wcSettings.woocommerce_price_thousand_separator,
    format: '%s%v',
  };
  return currencyFormatter.format(priceNumber, formatOptions);
};

export const getOrderTotal = (order, wcSettings) => {
  if (order.total_refunded > 0) {
    return `<del>${parseCurrency(Number(order.total), wcSettings, order.currency)}</del> ${parseCurrency(order.remaining_refund_amount, wcSettings, order.currency)}`;
  }
  return parseCurrency(order.total, wcSettings, order.currency);
};

export const parseDate = (date, wcSettings) => {
  const dateForDayJs = date;
  // if (date.substr(date.length - 5) !== '.000Z') {
  //   dateForDayJs = `${date}.000Z`;
  // }
  return dayjs(dateForDayJs)
  // .locale(importedLocal)
    .format(wcSettings.dateFormat);
};

export const parseDateTime = (date, wcSettings) => {
  const dateForDayJs = date;
  return dayjs(dateForDayJs)
    .format(wcSettings.dateTimeFormat);
};

export const exclVatLabel = (label) => `<br><small>(${label})</small>`;

export const availableOrderActions = {
  'wc-cancelled': [],
  'wc-completed': [],
  'wc-failed': ['pay', 'cancel'],
  'wc-on-hold': [],
  'wc-pending': ['pay', 'cancel'],
  'wc-processing': [],
  'wc-refunded': [],
};

export const createAndStorePaymentIntent = async (store, body = null) => {
  store.commit('SET_CART_LOADING', true);
  const { data } = await new GDSWC().createPaymentIntent(body);
  store.commit('SET_PAYMENT_METAS', {
    id: 'stripe',
    key: 'clientSecret',
    meta: data.client_secret,
  });
  store.commit('SET_CART_LOADING', false);
};

export const stripeMountCard = (store, paymentMetas) => {
  if (!document.querySelector('#card-element')) {
    return false;
  }

  const elements = paymentMetas.stripe.stripe.elements();

  const style = {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  };

  const card = elements.create('card', {
    hidePostalCode: true,
    style,
  });
  store.commit('SET_PAYMENT_METAS', {
    id: 'stripe',
    key: 'card',
    meta: card,
  });

  paymentMetas.stripe.card.mount('#card-element');

  paymentMetas.stripe.card.on('change', (event) => {
    const { error } = event;
    // TODO Disable the Pay button if there are no card details in the Element
    // document.querySelector("button").disabled = event.empty;
    const displayError = document.getElementById('card-errors');
    if (error) {
      displayError.textContent = error.message;
    } else {
      displayError.textContent = '';
    }
  });

  return true;
};

const stripePayWithCard = async (store, paymentMetas, source = null) => {
  store.commit('SET_CART_LOADING', true);

  const payment_method = source || {
    card: paymentMetas.stripe.card,
  };

  const result = await paymentMetas.stripe.stripe.confirmCardPayment(paymentMetas.stripe.clientSecret, {
    payment_method,
  });

  store.commit('SET_CART_LOADING', false);
  if (result.error) {
    store.commit('SET_SNACKBAR', { active: true, content: result.error.message });
    return false;
  }
  return result.paymentIntent ? result.paymentIntent.id : result.id;
};

export const handleStripePayWithCard = async (store, data, paymentGateway) => {
  let paymentResult = true;
  if (paymentGateway === 'stripe') {
    paymentResult = await stripePayWithCard(store, data.paymentMetas, data.source);
  }
  return paymentResult;
};

export const handlePaymentAfterOrder = (store, data, paymentGateway) => {
  let paymentResult = true;
  if (paymentGateway === 'paypal') {
    paymentResult = false;
    if (data.metas.result === 'success') {
    // this.$store.dispatch('clearCart'); altrimenti sbianca pagina prima ancora di fare window.location
      window.location = data.metas.redirect;
    } else {
      store.commit('SET_SNACKBAR', { active: true, content: 'Error in saving order' });
    }
  }
  return paymentResult;
};

export const addableToCart = (product) => {
  if (!product.purchasable) {
    return false;
  }
  return true;
};

export const isOos = (product) => product.stock_status === 'outofstock' || product.stock_status < 1;

export const addCardToCustomerSavedMethods = async (store, {
  stripeInstance,
  newAccountCredentials,
}) => {
  store.commit('SET_CART_LOADING', true);

  const { source, sourceError } = await stripeInstance.stripe.createSource(
    stripeInstance.card, { type: 'card' },
  );

  if (sourceError) {
    console.error(sourceError);
    store.commit('SET_CART_LOADING', false);
    throw new Error(sourceError.toString());
  }
  // console.log(source);

  try {
    /* const { data: assignResult } = */await new GDSWC().assignSourceToCustomer({
      source,
      newAccountCredentials,
    });
    // console.log(assignResult);

    const { data: setupIntent } = await new GDSWC().createSetupIntent({
      source,
      newAccountCredentials,
    });
    // console.log(setupIntent);

    /* const setupResult = */await stripeInstance.stripe.confirmCardSetup(setupIntent.client_secret, {
      payment_method: source.id,
    });
    // console.log(setupResult);

    const { data: addCardResult } = await new GDSWC().createPaymentSource({
      source,
      newAccountCredentials,
    });
    // console.log(addCardResult);

    store.commit('SET_CART_LOADING', false);
    return addCardResult;
  } catch (e) {
    store.commit('SET_CART_LOADING', false);
    throw e;
  }
};

export const availableSubscriptionActions = {
  'wc-active': ['change-payment', 'renew-now', 'cancel'],
  'wc-cancelled': [],
  'wc-expired': [],
  'wc-on-hold': ['cancel'],
  'wc-pending': ['pay', 'cancel'],
  'wc-pending-cancel': [],
  'wc-switched': [],
};
