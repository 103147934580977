<template>
  <div :class="['columns', `columns--${data.settings.spacing}`]">
    <Title
      v-if="data.value.title && data.value.title !== ''"
      :value="data.value.title"
      theme="uppercase"
      typo="h3--trimmed"
    />
    <div class="body">
      <div
        v-for="(column, index) in data.value.columns"
        :key="index"
        :style="{
          gridColumn: $mq.isMobile
            ? 'initial'
            : `${columnStart(index)} / span 2`,
        }"
      >
        <header class="column-header">
          <Figure
            v-if="column.icon"
            theme="icon"
            disable-ratio
            :lazyload="false"
            :data="{
              value: { src: column.icon },
              settings: {},
            }"
          />
          <Title
            v-if="column.title && column.title !== ''"
            :value="column.title"
            theme="uppercase"
            typo="h3--trimmed"
          />
        </header>
        <RichText
          v-if="column.text && column.text !== ''"
          :value="column.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import RichText from '@/components/typo/richtext';
import Title from '@/components/typo/title';

export default {
  components: {
    Figure,
    RichText,
    Title,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    columnStart(index) {
      let columnStart = 1;
      const increment = 3;

      if (index > 0) {
        columnStart += index * increment;
      }

      return columnStart;
    },
  },
};
</script>

<style lang="scss" scoped>
  .columns {
    display: grid;
    column-gap: var(--column-gap-xs);

    @include mq(m) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    > * {
      grid-column: 2 / -1;
    }

    .body {
      margin-top: calc(var(--spacer-xl) / 2);
      display: grid;
      column-gap: var(--column-gap-xs);
      row-gap: calc(var(--row-gap-l) / 2);

      @include mq(m) {
        grid-template-columns: repeat(10, minmax(0, 1fr));
      }

      .column-header {
        display: grid;
        row-gap: var(--row-gap-s);
      }

      > * {
        display: grid;
        gap: var(--row-gap-m);
        align-content: start;
      }
    }

    &--l {
      > .title {
        grid-column: initial;
      }

      .body {
        @include mq(m) {
          margin-top: 0;
        }
        row-gap: var(--row-gap-l);
      }
    }
  }
</style>
