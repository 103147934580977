<template>
  <component
    :is="layouts.get(data.settings.product_preview_layout)"
    :value="data.value"
  />
</template>

<script>
import TwoCenter from '@/components/blocks/product-preview/two-center';
import TwoRight from '@/components/blocks/product-preview/two-right';
import ThreeInline from '@/components/blocks/product-preview/three-inline';
import ThreeLeft from '@/components/blocks/product-preview/three-left';
import ThreeCenter from '@/components/blocks/product-preview/three-center';
import ThreeRight from '@/components/blocks/product-preview/three-right';
import ThreeFull from '@/components/blocks/product-preview/three-full';

export default {
  name: 'ProductPreview',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      layouts: new Map([
        ['two-center', TwoCenter],
        ['two-right', TwoRight],
        ['three-inline', ThreeInline],
        ['three-left', ThreeLeft],
        ['three-center', ThreeCenter],
        ['three-right', ThreeRight],
        ['three-full', ThreeFull],
      ]),
    };
  },
};
</script>

<style lang="scss">
  .product-preview {
    --preview-columns: 2;
    display: grid;

    @media (hover: hover) {
      &:hover {
        .thumb--product:not(.placeholder) {
          .enquiry {
            visibility: initial;
          }
        }
      }
    }

    .placeholder {
      display: grid;
      row-gap: var(--row-gap-xs);
    }
  }
</style>
