var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"page not-found"},[_c('Icon',{attrs:{"theme":"l","name":"ghost"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$labels().not_found_text)+" ")]),_c('Link',{attrs:{"theme":"grey","typo":"link","data":{
      title: _vm.$labels().back_home_cta,
      fn: () =>
        _vm.$router.push({
          name: 'Home',
          params: {
            lang: _vm.lang,
          },
        }),
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }