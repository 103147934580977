<template>
  <div :class="['gallery', theme ? `gallery--${theme}` : 'gallery--plain']">
    <div
      v-if="gallery && theme && data.value.gallery.length > 1"
      class="counter typo--h3--trimmed"
    >
      {{ `${gallery.selectedIndex + 1}/${data.value.gallery.length}` }}
    </div>
    <div
      v-if="theme"
      ref="gallery"
      class="content"
      :style="{ height: `${height}px` }"
    >
      <div
        v-for="item in data.value ? data.value.gallery : data"
        :key="item.id"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          ref="figure"
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          :theme="theme"
          :lazyload="false"
          disable-ratio
          :style="{ width: `${height * targetRatio}px` }"
        />
        <button
          v-if="data.value.gallery.length > 1"
          :style="{ width: `${height * targetRatio}px` }"
          class="next"
          @click="next"
        />
        <button
          v-if="data.value.gallery.length > 1"
          class="previous"
          :style="{ width: `${(height * item.width) / item.height / 2}px` }"
          @click="previous"
        />
      </div>
    </div>
    <div
      v-else
      ref="gallery"
      class="content"
    >
      <div
        v-for="(item, index) in data.value ? data.value.gallery : data"
        :key="index"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          ref="figure"
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          theme="product"
          :lazyload="false"
          disable-ratio
        />
        <button
          v-if="data.length > 1"
          class="next"
          @click="changeMaterial(true)"
        />
        <button
          v-if="data.length > 1"
          class="previous"
          @click="changeMaterial(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ['change-material'],
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        contain: 'true',
        prevNextButtons: false,
        draggable: !!this.$mq.isTouchDevice,
        pageDots: false,
        setGallerySize: false,
        wrapAround: true,
      },
      targetIndex: 0,
      targetRatio: 0,
      height: 0,
      width: 0,
    };
  },
  mounted() {
    this.gallery = new Flickity(
      this.$refs.gallery,
      Object.assign(this.settings, this.extraSettings),
    );
    if (this.theme) {
      this.setTargetItem();
      this.setHeight();
      this.$bus.$on('windowResized', this.handleResize);
    }
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  beforeDestroy() {
    if (this.theme) {
      this.$bus.$off('windowResized', this.handleResize);
    }
  },
  methods: {
    setTargetItem() {
      const images = this.data.value.gallery;
      let targetRatio = 0;

      images.forEach((image, index) => {
        const { width } = image;
        const { height } = image;
        const ratio = width / height;

        if (ratio > targetRatio) {
          targetRatio = ratio;
          this.targetIndex = index;
        }
      });

      this.targetRatio = targetRatio;
    },

    setHeight() {
      const maxHeight = 600;

      const targetHeight = this.$refs.gallery.clientWidth / this.targetRatio;

      if (targetHeight < maxHeight) {
        this.height = targetHeight;
      } else this.height = maxHeight;
    },

    handleResize() {
      this.setHeight();
    },

    previous() {
      this.gallery.previous(false, true);
    },

    next() {
      this.gallery.next(false, true);
    },
    changeMaterial(direction) {
      this.$emit('change-material', direction);
    },
  },
};
</script>

<style lang="scss">
.gallery {
  align-items: end;
  display: grid;
  gap: var(--column-gap-xs);

  .counter {
    grid-row: 2;
  }

  @include mq(m) {
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .content {
      grid-column: 2 / -1;
    }

    .counter {
      grid-row: initial;
    }
  }

  button {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
  }

  .previous {
    cursor: url("~@/assets/svg/icons/arrow-left.svg"), auto;
  }

  .next {
    cursor: url("~@/assets/svg/icons/arrow-right.svg"), auto;
  }

  &--plain {
    display: initial;

    .flickity-viewport {
      height: calc(var(--vh) * 100);
    }

    button {
      width: 50%;
    }

    .next {
      right: 0;
      left: initial;
    }
  }

  .item {
    width: 100%;
    height: 100%;

    // &:not(.is-selected) {
    //   opacity: 0;
    // }
  }
}
</style>
