<template>
  <component
    :is="media.get(data.value.src.type)"
    class="media"
    :data="data"
  />
</template>

<script>
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';

export default {
  name: 'Media',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      media: new Map([
        ['image', Figure],
        ['video', Video],
      ]),
    };
  },
};
</script>

<style></style>
