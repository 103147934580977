<template>
  <div class="group">
    <component
      :is="components.get(parseBlockName(block.blockName))"
      v-for="(block, index) in data.innerBlocks"
      :key="index"
      :data="block.blockName.includes('acf/') ? block.attrs.data : block"
    />
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components';

export default {
  name: 'Group',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parseBlockName,
      components,
    };
  },
};
</script>

<style lang="scss" scoped>
.group {
  display: grid;
  gap: var(--row-gap-group);

  /deep/ .columns {
    margin-top: calc(var(--row-gap-l) - var(--row-gap-group));

    @include mq(m) {
      margin-top: 0;
    }
  }
}
</style>
