<template>
  <div class="gallery-block">
    <Title
      v-if="data.value.title !== ''"
      :value="data.value.title"
      theme="uppercase"
      :typo="$mq.isMobile ? 'h3' : 'h3--trimmed'"
    />
    <Gallery
      theme="block"
      :data="data"
    />
  </div>
</template>

<script>
import Gallery from '@/components/media/gallery';
import Title from '@/components/typo/title';

export default {
  components: {
    Gallery,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .gallery-block {
    display: grid;
    gap: var(--column-gap-xs);

    @include mq(m) {
      grid-template-columns: repeat(6, minmax(0, 1fr));

      > * {
        grid-row: 1;
        grid-column: 1;
      }

      > .gallery {
        grid-column: 1 / -1;
      }
    }
  }
</style>
