<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :class="['icon', `icon--${theme}`, `icon--${name}`]"
    :to="to ? (typeof to === 'string' ? $relativeUrl(to) : to) : undefined"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
  .icon {
    svg {
      stroke: var(--black);
      height: 7px;
      width: 7px;
    }

    &--l {
      svg {
        stroke: none;
        height: 15px;
        width: 15px;
      }
    }
  }
</style>
