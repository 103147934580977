<template>
  <div
    :class="[
      'price',
      `typo--${typo}`,
      theme !== '' ? `price--${theme}` : false,
    ]"
  >
    <div v-html="product.price_html" />
    <div>
      {{
        vatExcluded
          ? $labels().price_vat_excluded
          : $labels().price_vat_included
      }}
    </div>
  </div>
  <!--
    Per personalizzare prezzo
    <span>
    <del
      v-if="product.sale_price"
      v-html="parseCurrency(product.regular_price)"
    />
    <component
      :is="product.sale_price ? 'ins' : 'span'"
      v-html="parseCurrency(product.price)"
    />
  </span> -->
</template>

<script>
import { mapGetters } from 'vuex';

import { parseCurrency } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {},
  props: {
    product: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
    typo: {
      type: String,
      default: 'h3',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['wcSettings']),
    vatExcluded() {
      return (
        this.$store.state.wp.wcSettings.woocommerce_tax_display_shop
          === 'excl'
      );
    },
  },
  mounted() {},
  methods: {
    parseCurrency(price) {
      return parseCurrency(price, this.wcSettings);
    },
  },
};
</script>

<style lang="scss" scoped>
  .price {
    &--inline {
      > * {
        display: inline;
      }
    }
  }
</style>
