<template>
  <footer
    v-if="$route.params.slug === 'contacts'"
    class="footer"
  >
    <div class="body">
      <Title
        theme="uppercase"
        typo="h3--trimmed"
        :value="footer.title"
      />
      <RichText
        typo="p--trimmed"
        :value="footer.column_1"
      />
      <RichText
        typo="p--trimmed"
        :value="footer.column_2"
      />
      <RichText
        typo="p--trimmed"
        :value="footer.column_3"
      />
      <RichText
        theme="social"
        typo="p--trimmed"
        :value="footer.column_4"
      />
    </div>
    <Link
      theme="plain"
      typo="h3"
      :data="{
        title: $labels().credits,
        url: 'https://www.gigadesignstudio.com/',
        target: '_blank',
      }"
      class="credits"
    />
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import RichText from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Title from '@/components/typo/title';

export default {
  name: 'Footer',
  components: {
    RichText,
    Title,
    Link,
  },
  computed: {
    ...mapGetters(['options', 'lang']),
    footer() {
      const { footer, 'footer-en': footerEn } = this.options;
      return this.lang === 'en' ? footerEn : footer;
    },
  },
};
</script>

<style lang="scss">
.footer {
  display: grid;
  row-gap: var(--row-gap-l);
  column-gap: var(--row-gap-xs);

  @include mq(m) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .body {
    align-content: start;
    align-items: start;
    display: grid;
    column-gap: var(--column-gap-xs);
    row-gap: var(--column-gap-s);
    grid-column: 1 / -1;

    @include mq(m) {
      grid-template-columns: repeat(12, minmax(0, 1fr));

      > :nth-child(2) {
        grid-column: 3 / span 3;
      }

      > :nth-child(3) {
        grid-column: 6 / span 3;
      }

      > :nth-child(4) {
        grid-column: 9 / span 3;
      }
    }
  }

  .credits {
    grid-column: 2 / -1;
    margin-top: var(--row-gap-l);

    @include mq(m) {
      margin-top: 0;
    }
  }
}
</style>
