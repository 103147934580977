<template>
  <main class="collection">
    <Gutenberg
      :data="post.gds_blocks"
      theme="collection"
    />
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';
import data from '@/mixins/data';

export default {
  name: 'Collection',
  components: {
    Gutenberg,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>
  .collection {
    padding: var(--spacer-page);
    margin-top: var(--spacer-page-top);
    margin-bottom: var(--spacer-page-bottom);
  }
</style>
