<template>
  <main class="page not-found">
    <Icon
      theme="l"
      name="ghost"
    />
    <div>
      {{ $labels().not_found_text }}
    </div>
    <Link
      theme="grey"
      typo="link"
      :data="{
        title: $labels().back_home_cta,
        fn: () =>
          $router.push({
            name: 'Home',
            params: {
              lang,
            },
          }),
      }"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';
import Link from '@/components/typo/link';

export default {
  name: 'NotFound',
  components: {
    Icon,
    Link,
  },
  computed: {
    ...mapGetters(['lang']),
  },
};
</script>

<style lang="scss" scoped>
  .not-found {
    column-gap: var(--column-gap-xs);
    row-gap: var(--row-gap-m);

    @include mq(m) {
      grid-template-columns: repeat(6, 1fr);

      > * {
        grid-column: 2 / -1;
      }
    }
  }
</style>
