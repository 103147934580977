<template>
  <main
    v-if="!loading"
    class="product"
  >
    <header class="product-header">
      <Gallery
        v-if="productGallery.length"
        ref="product-gallery"
        class="product__gallery"
        :data="productGallery"
        @change-material="changeMaterial"
      />
      <div
        v-if="hasVariations"
        class="variation-selector"
      >
        <div
          v-for="variation in variations"
          :key="variation.id"
        >
          <input
            :id="variation.id"
            v-model="
              attributes.find((attribute) => attribute.id === 'pa_material')
                .model
            "
            type="radio"
            name="variation"
            :value="variation.attributes_slugs.pa_material"
            @change="updateSelectedVariation"
          >
          <label
            :style="{
              background: materialColor(variation.attributes_slugs.pa_material),
            }"
            :for="variation.id"
          />
        </div>
      </div>
      <div
        :class="[
          'info',
          post.acf.text_color === 'white' ? 'info--white' : false,
        ]"
      >
        <Title
          :value="post.name"
          typo="h3--trimmed"
        />
        <Richtext
          theme="italic"
          typo="h3--trimmed"
          :value="description"
        />
        <Price
          v-if="product"
          v-visible="product.price !== '' && product.price !== '0'"
          typo="h3--trimmed"
          theme="inline"
          :product="product"
        />
        <Enquiry
          theme="plain"
          :subject="product.name"
        />
        <div class="info--mobile">
          <Link
            theme="uppercase"
            typo="h3"
            :icon="mobileInfo ? 'minus' : 'plus'"
            :data="{ title: $labels().info_cta, fn: toggleMobileInfo }"
            icon-first
          />
          <div
            v-show="mobileInfo"
            class="body"
          >
            <div>
              <Richtext
                theme="italic"
                typo="h3"
                :value="description"
              />
              <Price
                v-if="product"
                theme="inline"
                :product="product"
              />
            </div>
            <Enquiry
              theme="plain"
              :subject="product.name"
            />
          </div>
        </div>
      </div>
    </header>
    <RelatedProducts
      :category="post.gds_taxonomies.product_cat[0]"
      :products="post.related"
    />
    <!-- <div v-if="hasVariations">
      <div
        v-for="(attribute, index) in attributes"
        :key="index"
      >
        <Flex>
          <Select
            v-model="attribute.model"
            class="product__variation-selector"
            :data="getAttribute(attribute)"
            :model.sync="attribute.model"
          >
            <label
              slot="label"
              for="terms"
            >{{ attribute.name }}</label>
          </Select>
          <Icon
            v-if="attribute.model"
            name="close"
            theme="small"
            :fn="() => resetAttribute(attribute)"
          />
        </Flex>
      </div>
    </div> -->
    <!-- <Availability :product="product" /> -->
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import isEqual from 'lodash.isempty';
import { addableToCart, isOos } from '@/assets/js/utils-wc';
import { nl2br } from '@/assets/js/utils';

import data from '@/mixins/data';

import Gallery from '@/components/media/gallery';
import Title from '@/components/typo/title';
import Link from '@/components/typo/link';
import Enquiry from '@/components/ui/enquiry';
import Richtext from '@/components/typo/richtext';
// import Icon from '@/components/ui/icon';
import Price from '@/components/wc/price';
import RelatedProducts from '@/components/wc/related-products';
// import Availability from '@/components/wc/availability';

// import Select from '@/components/form/fields/select';

// N.B. testato fino a 2 attributi

export default {
  name: 'Product',
  components: {
    Gallery,
    Title,
    Link,
    Richtext,
    Enquiry,
    // Icon,
    Price,
    RelatedProducts,
    // Availability,
    // Select,
  },
  mixins: [data],
  data() {
    return {
      loading: true,
      product: null,
      variations: [],
      attributes: [],
      selectedVariation: null,
      selectedImage: null,
      numberToBeAdded: 1,
      metaInfos: {},
      mobileInfo: false,
    };
  },
  computed: {
    ...mapGetters(['cartLoading', 'lang', 'materialColor']),
    hasVariations() {
      return this.post.type.startsWith('variable');
    },
    hasMetaInfos() {
      return (
        this.product.acf
          && this.product.acf.meta_infos
          && this.product.acf.meta_infos[0]
      );
    },
    isOos() {
      return isOos(this.product);
    },
    addableToCart() {
      if (this.hasVariations && !this.selectedVariation) {
        return false;
      }
      if (!addableToCart(this.product) || isOos(this.product)) {
        return false;
      }
      return true;
    },
    productGallery() {
      const array = this.post.gds_featured_image && this.variations.length === 0
        ? [this.post.gds_featured_image]
        : [];
      this.variations.forEach((variation) => {
        this.post.gallery.push(variation.gds_featured_image);
      });
      return array.concat(this.post.gallery ? this.post.gallery : []);
    },
    description() {
      let description = '';

      if (this.product.acf && this.product.acf.description !== '') {
        description = this.product.acf.description;
      }

      if (this.product.description && this.product.description !== '') {
        description = this.product.description;
      }

      if (this.hasVariations) {
        return nl2br(description);
      }
      return description;
    },
  },
  watch: {
    $route() {
      this.setSelectedVariation();
    },
    selectedImage() {
      if (this.$refs['product-gallery'] && this.$refs['product-gallery']) {
        setTimeout(() => {
          this.$refs['product-gallery'].gallery.selectCell(
            `[data-item-id="${this.selectedImage}"]`,
            false,
            true,
          );
        }, 100); // Await flickity loading
      }
    },
    loading(val) {
      if (!val) {
        this.$nextTick(() => {
          if (
            this.$refs['product-gallery']
              && this.$refs['product-gallery']
          ) {
            this.$refs['product-gallery'].gallery.selectCell(
              `[data-item-id="${this.selectedImage}"]`,
            );
          }
        });
      }
    },
  },
  async created() {
    this.product = this.post;
    if (this.hasVariations) {
      this.$store.commit('SET_CART_LOADING', true);
      const variations = await this.$store.dispatch('getVariations', {
        slug: this.post.slug,
        lang: this.lang,
      });

      this.variations = variations;
      const attributesKeys = Object.keys(this.post.attributes_slugs);
      attributesKeys.forEach((attribute_slug, index) => {
        const newAttribute = {
          type: 'select',
          model: null,
          id: attribute_slug,
          name: this.post.attributes[index].name,
          hidden: false,
          placeholder: null,
          options: {},
          originalOptions: {},
          fn: this.updateSelectedVariation,
        };
        this.post.attributes[index].options.forEach((option, i) => {
          // Check if exists any variation with this attribute
          if (
            this.variations.findIndex(
              (variation) => variation.attributes_slugs[attribute_slug]
                  === this.post.attributes_slugs[attribute_slug][i],
            ) > -1
          ) {
            newAttribute.options[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
            newAttribute.originalOptions[
              this.post.attributes_slugs[attribute_slug][i]
            ] = option;
          }
        });
        this.attributes.push(newAttribute);
      });
      this.setSelectedVariation();
      this.attributes[0].model = this.variations[0].attributes_slugs.pa_material;
      this.updateSelectedVariation();
      this.$store.commit('SET_CART_LOADING', false);
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  async mounted() {
    // Marketing
    this.$bus.$emit('viewItem', this.product);
  },
  methods: {
    callback() {
      this.addToCart();
    },
    addToCart() {
      const cart_item_data = {};
      if (this.hasMetaInfos) {
        this.product.acf.meta_infos.forEach((product) => {
          cart_item_data[product.slug] = this.metaInfos[product.slug];
        });
      }
      this.$store.dispatch(
        'addToCart',
        Object.assign(this.post, {
          id: String(this.post.id),
          variation_id: this.selectedVariation,
          variation: this.variations.find(
            (variation) => this.selectedVariation === variation.id,
          ),
          quantity: this.numberToBeAdded,
          quantityDelta: this.numberToBeAdded,
          cart_item_data,
        }),
      );
    },
    numberChanged(val) {
      if (val[0]) {
        /* eslint-disable */
          this.numberToBeAdded = val[1];
        } else {
          this.numberToBeAdded = val.target.value;
        }
        if (this.numberToBeAdded < 1) {
          this.numberToBeAdded = 1;
        }
        if (
          this.product.stock_quantity &&
          this.numberToBeAdded > this.product.stock_quantity
        ) {
          this.numberToBeAdded = this.product.stock_quantity;
        }
      },
      getAttribute(attribute) {
        const select = document.querySelector(`select#${attribute.id}`);
        const currentFixedOption = document.querySelector(
          ".field.focus select"
        );
        if (
          currentFixedOption &&
          select &&
          select.closest(".field") &&
          !select.closest(".field").classList.contains("focus")
        ) {
          // Re set original value
          const originalOptionsKeys = Object.keys(attribute.originalOptions);
          originalOptionsKeys.forEach((originalOptionsKey) => {
            if (!attribute.options[originalOptionsKey]) {
              attribute.options[originalOptionsKey] =
                attribute.originalOptions[originalOptionsKey];
            }
          });

          const currentFixedKey = currentFixedOption.getAttribute("id");
          const currentFixedValue = currentFixedOption.value;
          const optionsKeys = Object.keys(attribute.options);
          optionsKeys.forEach((optionsKey) => {
            const existingVariation = this.variations.filter((variation) => {
              return (
                variation.attributes_slugs[currentFixedKey] ===
                  currentFixedValue &&
                variation.attributes_slugs[attribute.id] === optionsKey
              );
            });
            if (!existingVariation[0]) {
              delete attribute.options[optionsKey];
            }
          });
        }

        return attribute;
      },
      resetAttribute(attribute) {
        attribute.model = null;
        this.resetSelectedVariation();
      },
      resetSelectedVariation() {
        this.selectedVariation = null;
        this.selectedImage = null;
      },
      setSelectedVariation() {
        const queryVariations = Object.keys(this.$route.query);
        if (queryVariations.length === 0) {
          this.attributes.forEach((attribute) => {
            attribute.model = null;
          });
          this.resetSelectedVariation();
        } else {
          // Re-attribute model if reloading page
          queryVariations.forEach((queryVariation) => {
            this.attributes.forEach((attribute) => {
              if (attribute.id === queryVariation.replace("attribute_", "")) {
                attribute.model = this.$route.query[queryVariation];
              }
            });
          });

          const selectedVariation = this.variations.find((variation) => {
            let isRightVariation = false;
            let correctAttributes = 0;
            this.attributes.forEach((attribute) => {
              if (
                attribute.model === variation.attributes_slugs[attribute.id]
              ) {
                correctAttributes += 1;
              }
            });
            if (correctAttributes === variation.attributes.length) {
              isRightVariation = true;
            }
            return isRightVariation;
          });
          if (selectedVariation) {
            this.selectedVariation = selectedVariation.id;
            this.selectedImage = selectedVariation.gds_featured_image
              ? selectedVariation.gds_featured_image.id
              : null;
            this.product = selectedVariation;
          } else {
            this.resetSelectedVariation();
          }
        }
        // Marketing
        this.$bus.$emit("selectItem", this.product);
      },
      updateSelectedVariation() {
        let attributesSelected = 0;
        const query = {};
        this.attributes.forEach((attribute) => {
          if (attribute.model) {
            query[`attribute_${attribute.id}`] = attribute.model;
            attributesSelected += 1;
          }
        });
        if (
          attributesSelected === this.attributes.length &&
          !isEqual(query, this.$route.query)
        ) {
          this.$router.replace({ query });
        }
      },
      toggleMobileInfo() {
        this.mobileInfo = !this.mobileInfo;
      },
      changeMaterial(direction) {
        const currentAttribute = this.attributes.find(
          (attribute) => attribute.id === "pa_material"
        );
        const currentIndex = this.variations.findIndex(
          (variation) => variation.id === this.selectedVariation
        );
        let nextIndex = direction ? currentIndex + 1 : currentIndex - 1;

        if (nextIndex < 0) {
          nextIndex = this.variations.length - 1;
        }
        if (nextIndex > this.variations.length - 1) {
          nextIndex = 0;
        }

        currentAttribute.model =
          this.variations[nextIndex].attributes_slugs.pa_material;
        this.updateSelectedVariation();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product {
    display: grid;
    row-gap: var(--row-gap-l);
    margin-bottom: var(--spacer-page-bottom);

    @include mq(m) {
      row-gap: initial;
    }

    .product-header {
      position: relative;
      height: calc(var(--vh) * 100);
    }

    .variation-selector {
      display: grid;
      padding: var(--spacer-xs);
      gap: var(--row-gap-m);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      @include mq(m) {
        gap: var(--row-gap-xs);
      }

      input {
        display: none;
      }

      input:checked + label {
        border: solid var(--line) var(--black);
      }

      label {
        --size: 12px;

        @include mq(m) {
          --size: 16px;
        }

        height: var(--size);
        display: grid;
        width: var(--size);
        cursor: pointer;
      }
    }

    .info {
      align-items: end;
      bottom: 0;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      left: 0;
      padding: var(--spacer-page);
      position: absolute;
      width: 100%;

      &--white {
        color: var(--white);

        /deep/svg {
          stroke: var(--white);
        }
      }

      @include mq(m) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }

      > .richtext,
      > .price {
        display: none;

        @include mq(m) {
          display: initial;
        }
      }

      > .enquiry {
        display: none;

        @include mq(m) {
          display: grid;
        }
      }

      > .richtext,
      > .price {
        grid-column: span 2;
      }

      &--mobile {
        display: grid;
        gap: var(--row-gap-m);

        @include mq(m) {
          display: none;
        }

        .body {
          display: grid;
          gap: var(--row-gap-m);
        }
      }
    }
  }
</style>
