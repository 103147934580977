<template>
  <div class="language-selector notranslate">
    <div
      v-for="item in langs"
      :key="item.code"
      class="typo--p--trimmed"
    >
      <button
        :data-selected="item.code === lang"
        :data-value="item.code"
        @click="changeLanguage"
      >
        {{ item.code }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      value: '',
    };
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
  },
  created() {
    this.value = this.lang;
  },
  methods: {
    checkLanguage(val) {
      return this.currentPost.wpml_translations.find(
        (translation) => translation.locale === val,
      );
    },
    changeLanguage(e) {
      if (e.target.dataset.value === this.value) return;
      this.value = e.target.dataset.value;

      let path = this.value === this.defaultLang ? '/' : `/${this.value}`;
      if (
        this.currentPost
          && this.currentPost
          && this.currentPost.wpml_translations
      ) {
        const currentTranslation = this.checkLanguage(this.value);
        if (currentTranslation) {
          path = this.$relativeUrl(currentTranslation.href);
        }
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
  .language-selector {
    display: grid;
    gap: var(--column-gap-xs);
    grid-auto-flow: column;
    width: max-content;

    button {
      text-transform: capitalize;

      &[data-selected="true"] {
        font-style: italic;
      }
    }
  }
</style>
