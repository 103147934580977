var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['gallery', _vm.theme ? `gallery--${_vm.theme}` : 'gallery--plain']},[(_vm.gallery && _vm.theme && _vm.data.value.gallery.length > 1)?_c('div',{staticClass:"counter typo--h3--trimmed"},[_vm._v(" "+_vm._s(`${_vm.gallery.selectedIndex + 1}/${_vm.data.value.gallery.length}`)+" ")]):_vm._e(),(_vm.theme)?_c('div',{ref:"gallery",staticClass:"content",style:({ height: `${_vm.height}px` })},_vm._l((_vm.data.value ? _vm.data.value.gallery : _vm.data),function(item){return _c('div',{key:item.id,staticClass:"item",attrs:{"data-item-id":item.id}},[_c('Figure',{ref:"figure",refInFor:true,style:({ width: `${_vm.height * _vm.targetRatio}px` }),attrs:{"data":{
          value: {
            src: item,
          },
          settings: {},
        },"theme":_vm.theme,"lazyload":false,"disable-ratio":""}}),(_vm.data.value.gallery.length > 1)?_c('button',{staticClass:"next",style:({ width: `${_vm.height * _vm.targetRatio}px` }),on:{"click":_vm.next}}):_vm._e(),(_vm.data.value.gallery.length > 1)?_c('button',{staticClass:"previous",style:({ width: `${(_vm.height * item.width) / item.height / 2}px` }),on:{"click":_vm.previous}}):_vm._e()],1)}),0):_c('div',{ref:"gallery",staticClass:"content"},_vm._l((_vm.data.value ? _vm.data.value.gallery : _vm.data),function(item,index){return _c('div',{key:index,staticClass:"item",attrs:{"data-item-id":item.id}},[_c('Figure',{ref:"figure",refInFor:true,attrs:{"data":{
          value: {
            src: item,
          },
          settings: {},
        },"theme":"product","lazyload":false,"disable-ratio":""}}),(_vm.data.length > 1)?_c('button',{staticClass:"next",on:{"click":function($event){return _vm.changeMaterial(true)}}}):_vm._e(),(_vm.data.length > 1)?_c('button',{staticClass:"previous",on:{"click":function($event){return _vm.changeMaterial(false)}}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }