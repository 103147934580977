<template>
  <div class="enquiry-block">
    <Enquiry
      :block="true"
      :subject="data.value ? data.value.email_subject : null"
    />
  </div>
</template>

<script>
import Enquiry from '@/components/ui/enquiry';

export default {
  components: {
    Enquiry,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
  .enquiry-block {
    display: grid;
    gap: var(--column-gap-xs);

    @include mq(m) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .enquiry {
      @include mq(m) {
        grid-column: 2 / -1;
      }
    }
  }
</style>
