import Vue from 'vue';
import {
  is,
  getBreakpoint,
  isTouchDevice,
  isIOS,
  isSafari,
} from '@/assets/js/utils';

const setVh = () => {
  if (isTouchDevice()) {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  } else {
    document.documentElement.style.setProperty('--vh', '1vh');
  }
};

const setMq = () => ({
  breakpoint: getBreakpoint(),
  isTouchDevice: isTouchDevice(),
  isIOS: isIOS(),
  isSafari: isSafari(),
  is: (breakpoint) => is(breakpoint),
  isMobile: is('m'),
  isMobileXS: is('xs'),
  isMobileS: is('s'),
  vh: setVh,
});

const mq = {};

mq.install = () => {
  setVh();
  const mqObserver = Vue.observable(Object.assign(setMq(), { setMq }));

  Object.defineProperty(Vue.prototype, '$mq', {
    get() { return mqObserver; },
    set(value) { Object.assign(mqObserver, value); },
  });
};

Vue.use(mq);
