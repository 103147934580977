<template>
  <Link
    class="enquiry"
    :theme="theme ? `enquiry--${theme}` : 'enquiry'"
    typo="h3"
    :data="{ url: url, title: $labels().enquiry_cta }"
    icon="plus"
  />
</template>

<script>
import Link from '@/components/typo/link';

export default {
  components: { Link },
  props: {
    subject: {
      type: String,
      default: 'Generic enquiry',
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    url() {
      const email = 'info@flaviatschanz.ch';
      const { subject } = this;
      let body = this.$labels().enquiry_body;

      if (this.block) {
        body = this.$labels().enquiry_block_body;
      }

      return `mailto:${email}?subject=${subject}&body=${body}`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
