<template>
  <div class="related-products">
    <Title
      theme="uppercase"
      typo="h3--trimmed"
      :value="$labels().related_title"
    />
    <div class="thumbs">
      <Thumb
        v-for="product in products"
        :key="product.id"
        :data="product"
        theme="related"
      />
    </div>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Thumb from '@/components/thumb/product';

export default {
  components: {
    Thumb,
    Title,
  },
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
  .related-products {
    display: grid;
    gap: var(--column-gap-xs);
    padding: var(--spacer-xs);

    @include mq(m) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .thumbs {
      display: grid;
      column-gap: var(--column-gap-xs);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: var(--row-gap-l);

      @include mq(m) {
        grid-column: span 5 / -1;
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }

      > :last-child {
        display: none;

        @include mq(m) {
          display: grid;
        }
      }
    }
  }
</style>
