import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Figure from '@/components/media/figure';
import VideoPlayer from '@/components/media/video';
import Gallery from '@/components/blocks/gallery';
import Archive from '@/components/blocks/archive';
import Posts from '@/components/blocks/posts';
import Text from '@/components/blocks/text';
import ProductPreview from '@/components/blocks/product-preview';
import Columns from '@/components/blocks/columns';
import Enquiry from '@/components/blocks/enquiry';

const components = new Map();

components.set('title', Title);
components.set('paragraph', Richtext);
components.set('link', Link);
components.set('image', Figure);
components.set('video', VideoPlayer);
components.set('gallery', Gallery);
components.set('archive', Archive);
components.set('post', Posts);
components.set('text', Text);
components.set('product-preview', ProductPreview);
components.set('columns', Columns);
components.set('enquiry', Enquiry);

export default components;
