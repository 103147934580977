<template>
  <div
    v-if="!dismissed"
    class="news"
  >
    <div>
      <div class="head">
        <h4 class="typo--h4--trimmed">
          {{ date }}
        </h4>
        <h3
          v-if="value.title.rendered !== ''"
          class="typo--h3--trimmed"
        >
          {{ value.title.rendered }}
        </h3>
      </div>
      <RichText
        v-show="expanded"
        :value="value.content.rendered"
        typo="h4--trimmed"
      />
      <div
        class="toggle typo--h4"
        @click="toggle"
      >
        <button class="t-uppercase">
          {{ expanded ? $labels().read_less_cta : $labels().read_more_cta }}
        </button>
      </div>
    </div>
    <Icon
      name="close"
      :fn="dismiss"
    />
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';

import Icon from '@/components/ui/icon';
import RichText from '@/components/typo/richtext';

export default {
  name: 'News',
  components: {
    Icon,
    RichText,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      expanded: false,
      dismissed: Cookies.get(`flaviatschanz-news-${this.value.id}`),
    };
  },
  computed: {
    ...mapGetters(['lang']),
    date() {
      const date = new Date(this.value.date);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return date.toLocaleString(this.lang, options);
    },
  },
  methods: {
    dismiss() {
      this.dismissed = true;
      Cookies.set(`flaviatschanz-news-${this.value.id}`, 'dismissed', { expires: 7 });
    },
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
  .news {
    .head {
      display: grid;
      gap: var(--row-gap-s);
    }

    .richtext {
      margin-top: calc(var(--row-gap-m));
    }

    .toggle {
      margin-top: var(--spacer-l);
    }
  }
</style>
